<div class="page-header header-filter header-small" data-parallax="true"
		style="background-image: url('assets/img/bg1.jpg')">
		<div class="container">
      <div class="row">
        <div class="col-md-6">
          <h1 class="title">MiLACNIC Query</h1>
          <h4>
            {{ 'HOME.Textos.Header' | translate }}
          </h4>
        </div>
      </div>
		</div>
</div>
<div class="main main-raised" >
	<div *ngIf="error">
		<ul class="feedbackPanel">
			<li class="feedbackPanelERROR">
				<span>{{errorStr}}</span>
			</li>
		</ul>
	</div>
  <div class="section section-basic">
		<div class="container">
			<div class="row">
				<div class="col-md-6 ml-auto mr-auto text-center">
					<h2 class="title">{{ 'HOME.Textos.Buscar' | translate }}</h2>
				</div>
			</div>

			<div class="row">
				<div class="col-md-10 ml-auto mr-auto">
					<div id="searchPanel">
            <div class="card card-raised card-form-horizontal">
              <div class="card-body ">
              <form id="form">
                  <div class="row">
                    <div class="col-md-8">
                      <span class="bmd-form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text"> 
                              <i class="material-icons">search</i>
                            </span>
                          </div>
													<input 
														type="text"
                            placeholder="IPs, ASN, Rdap Objects, Rpsl Objects..."
                            class="form-control"  
														id="object"
														[(ngModel)]="query"
														name="query"
													>
                            
                          <!--
													<input 
                            type="hidden" 
                            name="recaptcha_response" 
														id="recaptchaResponse">
													-->
          
                        </div>
                      </span>
                    </div>
                    <div class="col-md-4">
                      <button class="btn btn-primary btn-block" id="buscar" (click)="onClickSearch()">{{ 'HOME.Botones.Buscar' | translate }}</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
				</div>
			</div>
		</div>
  </div>
  <div class="section section-basic">
		<div class="container">
			<div class="row">
				<div class="col-md-8 ml-auto mr-auto text-center">
					<h2 class="title">{{ 'HOME.Textos.Informacion' | translate }}</h2>
				</div>
			</div>

			<div class="row">
				<div class="col-md-2">
					<div class="card card-profile card-plain">
						<div class="card-avatar">
							<a> <img class="img" src="assets/img/iconos_irr.jpg">
							</a>
						</div>
						<div class="card-body">
							<p class="card-description">
								{{ 'HOME.Textos.IRR' | translate }}
							</p>

						</div>

						<div class="card-footer justify-content-center"></div>
					</div>
				</div>
				
				<div class="col-md-2">
					<div class="card card-profile card-plain">
						<div class="card-avatar">
							<a> <img class="img" src="assets/img/iconos_rdap.jpg">
							</a>
						</div>
						<div class="card-body">
							<p class="card-description">
								{{ 'HOME.Textos.RDAP' | translate }}
							</p>
						</div>

						<div class="card-footer justify-content-center"></div>
					</div>
				</div>

				<div class="col-md-2">
					<div class="card card-profile card-plain">
						<div class="card-avatar">
							<a> <img class="img" src="assets/img/iconos_whois.jpg">
							</a>
						</div>
						<div class="card-body">
							<p class="card-description">
								{{ 'HOME.Textos.WHOIS' | translate }}
							</p>
						</div>


						<div class="card-footer justify-content-center"></div>
					</div>
				</div>

				<div class="col-md-2">
					<div class="card card-profile card-plain">
						<div class="card-avatar">
							<a> <img class="img" src="assets/img/iconos_rdapweb.jpg">
							</a>
						</div>
						<div class="card-body">
							<p class="card-description">
								{{ 'HOME.Textos.RDAPWeb' | translate }}
							</p>
						</div>

						<div class="card-footer justify-content-center"></div>
					</div>
				</div>

				<div class="col-md-2">
					<div class="card card-profile card-plain">
						<div class="card-avatar">
							<a> <img class="img" src="assets/img/iconos_tools.jpg">
							</a>
						</div>
						<div class="card-body">
							<p class="card-description">
								{{ 'HOME.Textos.TOOLS' | translate }}
							</p>
						</div>


						<div class="card-footer justify-content-center"></div>
					</div>
				</div>


				<div class="col-md-2">
					<div class="card card-profile card-plain">
						<div class="card-avatar">
							<a> <img class="img" src="assets/img/iconos_rdapembedded.jpg">
							</a>
						</div>
						<div class="card-body">
							<p class="card-description">
								{{ 'HOME.Textos.RDAPEmbedded' | translate }}
							</p>
						</div>

						<div class="card-footer justify-content-center"></div>
					</div>
				</div>

			</div>
		</div>
	</div>
</div>
